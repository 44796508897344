<template>
	<div class="prolist">
		<v-header></v-header>
		<ul>
			<li v-for="(item,index) in tabs" :class="{active:index == num}" @click="tab(index)">{{item}}</li>
		</ul>
		<div class="lists">
			<div class="hotlist_left" v-for="hot in productlist" @click="toProDetail(hot.id)">
				<div class="hotimg">
					<img v-bind:src="hot.headimg" />
				</div>
				<div class="proname">{{hot.title}}</div>
			</div>
		</div>
		
	</div>
</template>

<script>
	import vHeader from '../components/Header.vue'
	export default {
		data() {
			return {
				tabs: ["综合", "销量", "价格"],
				num: 1,
				productlist:[],//热销列表
				keyword:""
			}
		},
		created() {
			this.GetHotList();
		},
		methods: {
			//tab切换
			tab(index) {
				this.num = index;
				if(this.num==0){
					this.GetZongHeList();
				}else if(this.num==1){
					this.GetHotList();
				}else if(this.num==2){
					this.GetPriceList();
				}
			},
			toProDetail(id){
				this.$router.push("/prodetail?id="+id)
			},
			//热销商品列表
			GetZongHeList() { //获取热销商品
				this.$store.dispatch("GET", {
					url: "/api/wx/?action=all"
				}).then(result => {
					this.productlist=result.data.data.rows;
				})
			},
			GetHotList() { //获取热销商品
				this.$store.dispatch("GET", {
					url: "/api/wx/?action=hot"
				}).then(result => {
					this.productlist=result.data.data.rows;
				})
			},
			GetPriceList() { //获取热销商品
				this.$store.dispatch("GET", {
					url: "/api/wx/?action=price"
				}).then(result => {
					this.productlist=result.data.data.rows;
				})
			}
		},
		components:{
			vHeader
		}
	}
</script>

<style scoped="scoped">
	ul{
		display: flex;
		height: 36px;
		line-height: 36px;
		background: #f6fafe;
	}
	li{
		flex: 1;
		text-align: center;
		font-size: 18px;
		color: #7b7b7b;
		list-style: none;
	}
	
	.active {
		color: #5c91e2;
		border-bottom: 2px solid #26a1ff;
	}
	
	.prolist {
		height: 100%;
		width: 100%;
		margin-bottom: 50px;
	}
	
	.lists {
		margin-top: 10px;
		padding-left: 2.6%;
	}
	
	.hotlist_left {
		float: left;
		border: 1px solid #bde2ff;
		width: 47.5%;
		margin-right: 1.5%;
		height: 57vw;
		background: #FFFFFF;
		margin-bottom: 10px;
	}
	
	.hotimg {
		text-align: center;
		padding-top: 20px;
		
	}
	
	.hotimg img {
		width: 40vw;
		height: 40vw;
	}
	
	.hotlist_middle {
		float: left;
		width: 2.5%;
	}
	
	.proname {
		text-align: center;
		font-size: 13px;
		color: #7b7b7b;
		 padding: 0px 5%;
		 height: 20px;
		 overflow: hidden;
		 text-overflow:ellipsis;
		 white-space:nowrap;
		 width:90%;
		 line-height: 20px;
	}
	.clear {
		clear: both;
	}
</style>